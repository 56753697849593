import React from "react"
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SeO from "../components/seo"

import { css } from '@emotion/react'
import { colors } from '../styles/constants'

import Modules from "../components/modules"

const pedagoObjectives = [
    {
        subtitle: "Stratégie Vidéo",
        title: "Stratégiser sa production vidéo",
        desc: "Comment trouver vos sujets, quoi dire, quoi filmer, et comment le faire"
    },
    {
        subtitle: "Production Vidéo",
        title: "Maîtriser le tournage vidéo",
        desc: "Comment bien filmer, sans perdre de temps, en allant à l'essentiel, sans stress"
    },
    {
        subtitle: "Production Vidéo",
        title: "Maîtriser le montage vidéo",
        desc: "Comment monter ses vidéos vite et bien, en utilisant des apps et logiciels très accessibles"
    },
    {
        subtitle: "Marketing Vidéo",
        title: "Maîtriser la diffusion vidéo",
        desc: "Comment publier et médiatiser ses vidéos pour obtenir des vues ciblées de qualité"
    },
    {
        subtitle: "Marketing Vidéo",
        title: "Maîtriser l'analyse vidéo",
        desc: "Comprendre les statistiques de vues de vos vidéos, et comment améliorer ces résultats"
    },
    {
        subtitle: "Maîtrise Vidéo",
        title: "Acquérir son autonomie complète",
        desc: "Comment choisir les futures vidéos pour continuer de développer son entreprise"
    },
    
]

const ModulesIntroPage = ( { data } ) => {
    const sociallogos = data.socialLogos

    // const webinar_sib_link = "https://721cadc9.sibforms.com/serve/MUIEAGiS-iSFxOiI4F5t1YZNmauSR_p4-Aks1G-7XSRgoLFdj1ve6kj0Fdg95NdrzMiRmnrx0s2yDB85RAHJbc1TvxH8PQ7x0o5hV2NjSNQNghyyD2TtZ4a6vjEhsMCXIuMYrLVbetD7O0YKOXaC0b_woerIBd8DrrtZq8tPl3qOtAtZN7GEduXBPa_lsti5vm2jpRq8seyLr-su"

    return (
      <Layout noContainer>
        <SeO title="Modules et réalisations vidéo" />
        <div css={css`
            margin-top: 100px;
        `} className="container constrained">
            <h1>Modules et réalisations du programme vidéo pour les PMEs</h1>
            <p className="content-intro" style={{marginBottom: "20px"}}>Le programme vous permet d'apprendre en faisant. Nous vous guidons dans la réalisation concrète de vos premières réalisation vidéos. Une fois l'aspect "technique" facilement assimilé par ces premières réalisations, nous vous guidons sur l'aspect "marketing" pour obtenir vos résultats business grâce à la vidéo. Vous voilà déjà indépendant.e !</p>
            <div className="flex--h--v-centered" style={{marginBottom: "40px"}}>                
                <Link to={`/inscription`} className="button--primary" style={{marginRight: "20px"}}>Inscription au programme</Link>
                {/* <SendInBlueForm 
                    form_title="webinar"
                    title="Voir le webinaire"
                    src={webinar_sib_link}
                /> */}
            </div> 

            <div css={toolsBanner} >
                <GatsbyImage image={sociallogos.childImageSharp.gatsbyImageData} alt={"Outils Video Lab"} />
            </div>
        </div>
    
        <div css={accentSection}>
            <div className="container">
                <h2>A la fin du programme, vous...</h2>
                <ul>
                    <li>Aurez réalisé en un temps record, avec simplicité et guidage, plusieurs vidéos utiles pour votre entreprise</li>
                    <li>Disposerez de tout le matériel marketing et explicatif pour mieux convaincre et augmenter vos ventes</li>
                    <li>Aurez publié, médiatisé, référencé ce contenu et reçu vos premières vues, qui vont continuer de croître</li>
                    <li>Aurez une autonomie complète dans votre production vidéo pour vos besoins futurs (site, réseaux sociaux, newsletter...)</li>
                    <li>Saurez exactement quoi faire pour la suite pour continuer à booster votre entreprise par la vidéo</li>
                </ul>
            </div>
        </div>

        <Modules />

        {/* <section className="container" css={webinarBox}>
            <h2><span className="stabilo">Webinaire gratuit</span> : démo du programme</h2>
            <p className="big">Nous vous avons préparé une présentation du programme et des bénéfices à en tirer pour votre activité. Inscrivez-vous gratuitement ci-dessous pour accéder à la présentation, et y découvrir aussi nos premiers conseils sur l'utilisation de la vidéo pour booster votre activité.</p>
            <div className="doodle" style={{color: "white"}}>Découvrez le potentiel de Video-Lab pour votre activité <span role="img" aria-label="regarder en dessous">👇</span></div>
            <SendInBlueForm 
            form_title="webinar"
            title="Voir le webinaire"
            src={webinar_sib_link}
            />
        </section> */}
    
        <div css={pedagoSection}>
            <div className="container">
                <h2>D'ici quelques heures, vous saurez...</h2>
                <p>Nos objectifs pédagogiques sont faits pour vous permettre d'acquérir les bases à toutes les étapes nécessaires : concept, tournage, montage, diffusion.</p>
            </div>
    
            <div className="two-cols" css={widerSection}>
                {pedagoObjectives.map( ( obj, index ) => {
                    return (
                        <div css={pedagoObj} key={obj.title}>
                            <div css={bigData} style={{color: colors.accent2}}>{index+1}</div>
                            <h4>{obj.subtitle}</h4>
                            <h3>{obj.title}</h3>
                            <p>{obj.desc}</p>
                        </div>
                    )
                })}
            </div>
        </div>

        <section css={accentSection} style={{marginTop: "70px", paddingBottom: "70px"}}>
            <div className="container">
                <h2 style={{marginTop: "0"}}>Commencez dès maintenant !</h2>
                <p>Avec notre programme en ligne, pas besoin d'attendre un séminaire de plusieurs jours en présentiel. Avec Video Lab, vous avez accès aux contenus à tout moment, ainsi qu'à la communauté et à nos coaches. Vous pouvez ainsi avancer à votre rythme, tout en gardant la motivation grâce à un parcours clair et, si vous le souhaitez, un suivi personnalisé ! D'ici quelques heures, vous aurez pris en main votre marketing digital par la vidéo, et votre entreprise ne sera plus jamais la même.</p>
                <Link to={`/inscription`} className="button--primary">Inscription au programme</Link>
            </div>
        </section>
    
      </Layout>
    )
}

export default ModulesIntroPage

const pedagoSection = css`
    margin-top: 100px;

    h4 {
        margin-bottom: 5px;
        margin-top: 0;
        font-size: 1.2rem;
        background-color: ${colors.whitegrey};
        color: ${colors.darkgrey};
        padding: 6px 8px 4px;
        display: inline-block;
        border-radius: 5px;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 0;
    }
`

const pedagoObj = css`
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
`

const widerSection = css`
    max-width: 1050px;
    margin: 40px auto 0;

    @media screen and (max-width: 1050px) {
        margin: 40px 60px 0;
    }
`

const bigData = css`
    font-size: 10rem;
    font-weight: 800;
    color: #fff700;
    position: absolute;
    top: 40px;
    left: -40px;
    z-index: -10;
`

const toolsBanner = css`
    padding: 20px 25px;
    background-color: white;
    box-shadow: 0 0 20px rgba(0,0,0,.11);
    border-radius: 100px;
    margin-bottom: -60px;
    position: relative;
    z-index: 200;

    @media screen and (max-width: 800px) {
        margin-bottom: -40px;
    }

    img {
        object-fit: contain !important;
    }
`

const accentSection = css`
    background-color: ${colors.whitegrey};
    padding: 70px 0;
`


/**
 * 
 * QUERY
 * 
 */

export const modulesQuery = graphql`
    query {
        socialLogos: file(name: {eq: "video-tools"}) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`